<template>
  <section id="menuPageLayout">
    <client-only>
      <MenuNavigator />
      <div id="products">
        <slot />
        <ShoppingCartPhone />
      </div>
      <!--  common modals-->
      <ReusableProductOverview v-if="selectedProduct" />
      <Deal v-if="selectedDealProduct" />
    </client-only>
  </section>
</template>

<script setup lang="ts">
import { useMenuSlice } from "~/store/menuSlice";
import { useProductsStore } from "~/store/productSlice";

const menuStore = useMenuSlice();
const productSlice = useProductsStore();
const { selectedProduct, selectedDealProduct } = storeToRefs(productSlice);
onMounted(() => {
  setTimeout(async () => {
    await menuStore.getAllProductsNavigator();
    await menuStore.getAllProductsByName();
    await menuStore.getAllRecommendedProduct();
  }, 100);
});
</script>
