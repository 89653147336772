<template>
  <Steps :model="stepsToShow" :active-step="activeStep" class="w-full" :readonly="false">
    <template #item="{ item, index }">
      <span class="p-menuitem-link" @click="emit('active-change', index)">
        <span
          class="p-steps-number"
          :class="item.complete.value && activeStep !== index ? 'p-completed' : ''"
        >
          {{ index + 1 }}
        </span>
        <span class="p-steps-title">{{ item.label }}</span>
      </span>
    </template>
  </Steps>
</template>

<script setup lang="ts">
defineProps({
  activeStep: {
    type: Number,
    default: 0,
  },
  stepsToShow: {
    type: Array as PropType<IStep[]>,
    default: () => [],
  },
});
const emit = defineEmits(["active-change"]);
</script>

<style scoped></style>
