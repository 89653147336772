<template>
  <div class="menu-navigator-category-container">
    <TabMenu :model="categoryNavigators" :style="{ gap: '4px' }">
      <template #item="{ item, index }">
        <MenuNavigatorCategoriesSelector
          :key="index"
          :label="item.label"
          :value="item.value"
          :click-handler="handleCategoryChange"
        />
      </template>
    </TabMenu>
  </div>
</template>

<script setup lang="ts">
import type { MenuNavigatorCategoryProps } from "~/types/componentProps/MenuNavigatorProduct";

defineProps<MenuNavigatorCategoryProps>();
const emit = defineEmits(["categoryNavigatorChange"]);
const handleCategoryChange = (value: string) => {
  emit("categoryNavigatorChange", { key: "category", value });
};
</script>
