<template>
  <div class="item-layout">
    <NuxtImg
      class="item-image"
      :src="product.img"
      :placeholder="loadingBox"
      alt="item-img"
      loading="eager"
    >
      <template #error>
        <img :src="loadingBox" alt="p-image" class="item-image" />
      </template>
    </NuxtImg>
    <div class="details-layout">
      <div class="flex justify-content-start align-items-center gap-1">
        <h1 class="header">
          <span class="product-number">{{
            product.name.split(".").length === 2 ? product.name.split(".")[0] + "." : ""
          }}</span>
          {{ product.name.split(".")[1] ?? product.name }}
        </h1>
        <div class="product-attributes">
          <div
            v-for="(attribute, index) in attributes as ProductAttributeType[]"
            :key="index"
            class="product-attribute"
          >
            <component :is="attributesComponents[attribute]" />
          </div>
        </div>
      </div>
      <p class="description">{{ product.dsc }}</p>
      <p v-if="!product.isCustomizable" class="description">
        {{ $t("allergens") }}:{{ product.allergens.map((i) => i.description).join(",") }}
      </p>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { ProductAttributeType, ProductEntity } from "~/types/entities/product.type";
import IconsVegIcon from "~/components/icons/VegIcon.vue";
import IconsSpicyX2Icon from "~/components/icons/SpicyX2Icon.vue";
import IconsSpicyX3Icon from "~/components/icons/SpicyX3Icon.vue";
import loadingBox from "assets/logo/loading_box.svg";

const attributesComponents = shallowRef({
  Veg: IconsVegIcon,
  SpicyX2: IconsSpicyX2Icon,
  SpicyX3: IconsSpicyX3Icon,
});
const props = defineProps({
  product: {
    type: Object as PropType<ProductEntity>,
    default: () => ({}),
  },
});
const productAttributeSeparator = (item: ProductEntity) => {
  const outPut = [];
  item.isVegan && outPut.push("Veg");
  switch (item.spiceLevel) {
    case "High":
      outPut.push("SpicyX3");
      break;
    case "Low":
      outPut.push("SpicyX1");
      break;
    case "Medium":
      outPut.push("SpicyX2");
      break;
  }
  return outPut;
};
const attributes = computed(() => {
  return productAttributeSeparator(props.product);
});
</script>

<style scoped></style>
