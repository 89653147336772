import { useProductsStore } from "~/store/productSlice";
import type { CustomizationElementType, ProductEntity } from "~/types/entities/product.type";
import { useCartSlice } from "~/store/cartSlice";
import type { CartItem } from "~/types/componentProps/cart";

export interface IStep {
  final: boolean;
  sequenceNumber: number;
  dealItems: ProductEntity[];
  label: string;
  disabled: boolean;
  complete: boolean;
  clickAction: () => void;
}

export interface IUseDealControl {
  activeStep: Ref<number>;
  stepsToShow: Ref<IStep[]>;
  changeActiveState: (step: number) => void;
  goNextStep: () => void;
}

export interface IUseDealMenuScreenControl {
  activeMenu: ComputedRef<IStep | null>;
  includeAndGoNext: (product: ProductEntity) => void;
  finalProducts: ComputedRef<ProductEntity[]>;
  cartAddableProduct: Ref<CartItem | null>;
  injectToCart: () => void;
  increaseCartAddableItemQuantity: () => void;
  decreaseCartAddableItemQuantity: () => void;
  showDetailsScreen: Ref<boolean>;
  toggleDetails: (product: ProductEntity) => void;
  customizaableProduct: Ref<ProductEntity | null>;
  closeDetailModal: () => void;
  nextStepName: ComputedRef<string>;
}

export function useDealCustomization() {
  const { t } = useI18n();
  const { logGoogleEventWithMiddleWare } = useConsentTracking();
  const cartSlice = useCartSlice();
  const productStore = useProductsStore();
  const { selectedDealProduct } = storeToRefs(productStore);
  const activeStep = ref(0);
  const completedStep = ref<{ [key: string]: ProductEntity }>({});
  const stepsToShow = computed(() => {
    const allSteps =
      selectedDealProduct.value?.dealInfo!.dealSteps.map((deal, index) => {
        return {
          ...deal,
          label: deal.description,
          disabled: computed(() => {
            if (activeStep.value === index) {
              return false;
            }
            return !(index + "" in completedStep.value);
          }).value,
          complete: computed(() => index + "" in completedStep.value),
          final: false,
        };
      }) || [];
    const currentLength = allSteps.length;
    allSteps.push({
      description: "",
      label: t("Confirm"),
      disabled: computed(() => {
        if (activeStep.value === currentLength) {
          return false;
        }
        return !(currentLength - 1 + "" in completedStep.value);
      }).value,
      complete: computed(() => currentLength - 1 + "" in completedStep.value),
      final: true,
      sequenceNumber: -1,
      dealItems: [],
    });
    return allSteps;
  });
  const changeActiveState = (step: number) => {
    activeStep.value = step;
  };
  const goNextStep = () => {
    // validate current step complete
    activeStep.value = activeStep.value + 1;
  };
  const includeAndGoNext = (product: ProductEntity) => {
    completedStep.value[`${activeStep.value}`] = product;
    goNextStep();
  };
  const cartAddableProduct = ref(cartSlice.createCartAddableItem(selectedDealProduct.value));
  watch(
    () => completedStep.value,
    () => {
      if (cartAddableProduct.value) {
        cartAddableProduct.value.product.customizableOptions.includedItem = Object.values(
          completedStep.value,
        ) as Partial<CustomizationElementType>[];
      }
    },
    { deep: true },
  );
  const injectToCart = () => {
    if (cartAddableProduct.value && cartAddableProduct.value.quantity > 0) {
      cartSlice.addToCart(cartAddableProduct.value);
      logGoogleEventWithMiddleWare("add_to_cart", {
        productId: cartAddableProduct.value.productId.toString(),
        productName: cartAddableProduct.value.product.name,
        quantity: cartAddableProduct.value.quantity.toString(),
      });
    }
  };

  function useDealControl(): IUseDealControl {
    return {
      activeStep,
      stepsToShow,
      changeActiveState,
      goNextStep,
    };
  }

  function useDealMenuScreenController(): IUseDealMenuScreenControl {
    const showDetailsScreen = ref<boolean>(false);
    const customizaableProduct = ref<ProductEntity | null>(null);
    const activeMenu = computed(() => {
      return stepsToShow.value ? stepsToShow.value[activeStep.value] : null;
    });
    const finalProducts = computed(() => {
      return Object.keys(completedStep.value).map((key) => completedStep.value[key]);
    });
    const increaseCartAddableItemQuantity = () => {
      if (cartAddableProduct.value) {
        cartAddableProduct.value.quantity++;
      }
    };
    const decreaseCartAddableItemQuantity = () => {
      if (cartAddableProduct.value && cartAddableProduct.value.quantity > 0) {
        cartAddableProduct.value.quantity--;
      }
    };
    const toggleDetails = (product: ProductEntity) => {
      if (product.isCustomizable && !showDetailsScreen.value) {
        customizaableProduct.value = product;
        showDetailsScreen.value = true;
      } else {
        includeAndGoNext(product);
      }
    };
    const closeDetailModal = () => {
      showDetailsScreen.value = false;
      customizaableProduct.value = null;
    };
    const nextStepName = computed(() => {
      return stepsToShow.value[activeStep.value + 1]?.label ?? "";
    });
    return {
      activeMenu,
      includeAndGoNext,
      finalProducts,
      cartAddableProduct,
      injectToCart,
      customizaableProduct,
      increaseCartAddableItemQuantity,
      decreaseCartAddableItemQuantity,
      showDetailsScreen,
      toggleDetails,
      closeDetailModal,
      nextStepName,
    };
  }

  return {
    useDealControl,
    useDealMenuScreenController,
  };
}
