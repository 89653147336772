<template>
  <div class="w-full flex flex-row justify-content-between align-items-center">
    <div class="w-full flex flex-column justify-content-start align-items-start">
      <div class="w-full flex flex-column justify-content-start align-items-start gap-1">
        <div class="w-full flex flex-row justify-content-between align-items-center">
          <p>
            <span class="customization-group-title">{{ attributeProperties.self.name }}</span>
            <span class="attribute-subtitle ml-1">{{ allergicItems }}</span>
          </p>
          <div class="flex flex-row justify-content-end align-items-center gap-2">
            <p class="customization-group-title">{{ $t(status) }}</p>
            <Checkbox
              v-model="included"
              class="rounded checker"
              :pt="{ box: { class: 'rounded checker' } }"
              binary
            />
          </div>
        </div>
        <div
          v-if="
            included && attributeProperties.self.isStandard && attributeProperties.self.addableItem
          "
          class="flex flex-row justify-content-start align-items-center gap-2 cursor-pointer mt-2"
        >
          <Checkbox
            key="excluding"
            v-model="extraIncluded"
            class="rounded checker"
            :pt="{
              box: { class: 'rounded checker' },
              icon: {
                class: extraIncluded ? 'checker' : 'pi-add',
              },
            }"
            binary
          >
            <template v-if="!extraIncluded" #icon>
              <i class="pi pi-plus" />
            </template>
          </Checkbox>
          <p class="customization-group-title">
            Ekstra {{ " " + "+" + attributeProperties.self.addableItem?.price }} kr
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { SupperCustomizationElementType } from "~/types/entities/product.type";

const props = defineProps({
  attributeProperties: {
    type: Object as PropType<SupperCustomizationElementType>,
    required: true,
  },
});
const included = computed({
  get: () => {
    return props.attributeProperties.isIncluded().value;
  },
  set: (newValue: boolean) => {
    if (newValue) {
      props.attributeProperties.includeSelf();
    } else {
      props.attributeProperties.excludeSelf();
    }
  },
});
const status = computed(() => {
  return included.value
    ? props.attributeProperties.self.isStandard
      ? "Included"
      : `+${props.attributeProperties.myPrice().value}Kr`
    : props.attributeProperties.self.isStandard
      ? "Excluded"
      : `+${props.attributeProperties.myPrice().value}Kr`;
});
const extraIncluded = computed({
  get: () => {
    return props.attributeProperties.myExtraIncludedItems().value;
  },
  set: (newValue: boolean) => {
    if (newValue) {
      props.attributeProperties.addAsExtraItem();
    } else {
      props.attributeProperties.removeAsExtraItem();
    }
  },
});
const allergicItems = computed(() => props.attributeProperties.myAllergens().value);
</script>
<style lang="scss" scoped>
.attribute-title {
  color: var(--Pure-Black, #000);

  /* M3/Body Medium */
  font-size: 16px;
  font-style: normal;
  font-weight: 325;
  line-height: 24px; /* 150% */
  letter-spacing: 0.25px;
}

.attribute-subtitle {
  color: var(--coal-60, #fff);

  /* Additional info */
  font-size: 14px;
  font-style: normal;
  font-weight: 325;
  line-height: 130%; /* 18.2px */
}
</style>
