<template>
  <div class="w-full flex flex-column justify-content-start align-items-start gap-2 mt-4">
    <div v-if="canBeHalfBacked" class="flex align-items-center gap-2">
      <Checkbox v-model="handleHalfBakedCchange" input-id="halfBacked" name="halfBacked" binary />
      <label for="halfBacked" class="option-title">{{ $t("halfBacked") }}</label>
    </div>
    <div v-if="canBeHalfAndHalf" class="flex align-items-center gap-2 mt-4">
      <Checkbox
        v-model="handleHalfAndHalfStatus"
        input-id="halfAndHalf"
        name="halfAndHalf"
        binary
      />
      <label for="halfAndHalf" class="option-title">Half & Half</label>
    </div>
    <div class="flex flex-column w-full justify-content-center align-content-start">
      <ReusableHalfAndhalfModal
        v-if="handleHalfAndHalfStatus"
        :half-and-half-pizza-list="halfAndHalfPizzaList"
        :selected-half="selectedHalf"
        @set-selected-half="handleSelectedHalf"
      />
    </div>
    <div v-if="!handleHalfAndHalfStatus" id="customizer-section" class="w-full">
      <div
        v-if="props.customizationOptions.length"
        class="w-full flex flex-column justify-content-start align-items-center"
      >
        <p class="customization-title">{{ $t("Customize") }}</p>
        <p class="customization-subtitle">
          {{ $t("Max10extraingredients", { context: props.productsResponse.maxAdditionalItems }) }}
        </p>
      </div>
      <div id="AccordionTabs" class="w-full">
        <ReusablePizzaCustomizerAccordion :options="props.customizationOptions" />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { CustomizationOptionsType, ProductEntity } from "~/types/entities/product.type";

const props = defineProps({
  customizationOptions: {
    type: Object as PropType<CustomizationOptionsType[]>,
    // eslint-disable-next-line vue/require-valid-default-prop
    default: () => [],
  },
  productsResponse: {
    type: Object as PropType<ProductEntity>,
    default: () => null,
  },
  halfBackedStatus: {
    type: Boolean,
    default: false,
  },
  changeHalfBackedStatus: {
    type: Function,
    default: (_value: boolean) => {},
  },
  halfAndHalfStatus: {
    type: Boolean,
    default: false,
  },
  changeHalfAndHalfStatus: {
    type: Function,
    default: (_value: boolean) => {},
  },
  halfAndHalfPizzaList: {
    type: Array as PropType<ProductEntity[]>,
    default() {
      return [];
    },
  },
  canBeHalfAndHalf: {
    type: Boolean,
    default() {
      return false;
    },
  },
  secondHalf: {
    type: Object as PropType<ProductEntity | null>,
    default: null,
  },
  setSecondHalf: {
    type: Function,
    default: () => {},
  },
  canBeHalfBacked: {
    type: Boolean,
    default: true,
  },
});
const handleHalfBakedCchange = computed({
  get() {
    return props.halfBackedStatus;
  },
  set(value: boolean) {
    props.changeHalfBackedStatus(value);
  },
});
const handleHalfAndHalfStatus = computed({
  get() {
    return props.halfAndHalfStatus;
  },
  set(value: boolean) {
    props.changeHalfAndHalfStatus(value);
  },
});
const selectedHalf = computed({
  get() {
    return props.secondHalf;
  },
  set(val: ProductEntity) {
    props.setSecondHalf(val);
  },
});
const handleSelectedHalf = (id: string) => {
  props.setSecondHalf(props.halfAndHalfPizzaList.find((i) => i.id === id));
};
</script>
