<template>
  <Card class="deal-container">
    <template #content>
      <DealControllerHeader :header-title="title" @close-modal="() => modalCloseAction()" />
      <DealControllerNav
        :active-step="activeStep"
        :steps-to-show="stepsToShow"
        @active-change="changeActiveState"
      />
      <slot />
    </template>
  </Card>
</template>

<script setup lang="ts">
import type { IUseDealControl } from "~/composables/useDealCustomization";

const { t } = useI18n();
const props = defineProps({
  modalCloseAction: {
    type: Function,
    default() {
      return () => {};
    },
  },
  dealHook: {
    type: Function as PropType<() => IUseDealControl>,
  },
});
const { activeStep, stepsToShow, changeActiveState } = props.dealHook!();
const title = computed(() => {
  return stepsToShow.value[activeStep.value].description
    ? t("Select") + " " + stepsToShow.value[activeStep.value].description
    : t("Confirm");
});
</script>

<style scoped></style>
