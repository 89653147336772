<template>
  <Dialog
    v-model:visible="modalPop"
    modal
    :position="position"
    :pt="{
      root: 'border-none',
      mask: {
        style: 'backdrop-filter: blur(2px)',
      },
    }"
  >
    <template #container="{ closeCallback }">
      <Card class="product-overview-card overflow-y-auto">
        <template #content>
          <div class="product-overview-header">
            <NuxtImg
              class="header-image"
              :src="selectedProduct?.img"
              :placeholder="loadingBox"
              :alt="selectedProduct?.name"
            >
              <template #error>
                <img :src="loadingBox" :alt="selectedProduct?.name" class="header-image" />
              </template>
            </NuxtImg>
            <div class="close-icon" @click="closeModal(closeCallback)">
              <IconsCloseButtonIcon />
            </div>
          </div>
          <div class="product-overview-content h-30rem">
            <div class="details">
              <div class="w-full">
                <p class="text-center w-full">
                  <span class="peppes-subtitle">{{
                    selectedProduct?.name.split(".").length === 2
                      ? selectedProduct.name.split(".")[0] + "."
                      : ""
                  }}</span>
                  <span class="peppes-title">{{
                    selectedProduct?.name.split(".")[1] ?? selectedProduct?.name
                  }}</span>
                  <span class="ml-2">
                    <IconsVegIcon v-if="selectedProduct?.isVegan" />
                  </span>
                  <span class="ml-2">
                    <component :is="attributesComponents[selectedProduct?.spiceLevel]" />
                  </span>
                </p>
              </div>
              <p class="text-center description">
                {{ selectedProduct?.dsc }}
              </p>
            </div>
            <!--           start of selecting pizza size-->
            <div class="w-full flex flex-row justify-content-between align-items-center gap-3">
              <div class="product-size-selector active">
                <div class="w-full flex flex-row justify-content-center align-items-center">
                  <div class="size-title-container">
                    <p class="size-title">
                      {{ selectedProduct!.size === "LARGE" ? $t("large") : $t("Small") }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div class="mt-4 flex flex-row justify-content-start align-items-center gap-4">
              <p>{{ $t("allergens") }}:</p>
              <p>
                {{ selectedProduct?.allergens.map((curr) => curr.description).join(", ") }}
              </p>
            </div>
          </div>
          <div class="peppes-price-actions">
            <div
              class="price-container justify-content-center align-items-center"
              @click="includeAndClose(() => closeModal(closeCallback))"
            >
              <p class="title">Neste{{ "(" + $t("Select") + " " + nextStepName + ")" }}</p>
            </div>
          </div>
        </template>
      </Card>
    </template>
  </Dialog>
</template>

<script setup lang="ts">
import loadingBox from "assets/logo/loading_box.svg";
import { type ProductEntity } from "~/types/entities/product.type";
import IconsVegIcon from "~/components/icons/VegIcon.vue";
import IconsSpicyX2Icon from "~/components/icons/SpicyX2Icon.vue";
import IconsSpicyX3Icon from "~/components/icons/SpicyX3Icon.vue";

const emit = defineEmits(["includeAndGo"]);
const props = defineProps({
  selectedProduct: {
    type: Object as PropType<ProductEntity | null>,
    default() {
      return null;
    },
  },
  showModal: {
    type: Boolean,
    default: false,
  },
  closeDetailModal: {
    type: Function as PropType<() => void>,
  },
  nextStepName: {
    type: String,
    default: "",
  },
});
const modalPop = computed(() => props.showModal);

const { width } = useWindowSize();
const { logGoogleEventWithMiddleWare } = useConsentTracking();
const closeModal = (cb: () => void) => {
  props.closeDetailModal!();
  cb();
};
const attributesComponents = shallowRef({
  Veg: IconsVegIcon,
  Medium: IconsSpicyX2Icon,
  High: IconsSpicyX3Icon,
});
const position = computed(() => {
  return width.value < 500 ? "bottom" : "center";
});
onMounted(() => {
  useNuxtApp().$firebaseTrackingFunctions.trackViewProductDetails(
    props.selectedProduct?.id.toString() || "",
  );
  logGoogleEventWithMiddleWare("select_item", {
    productId: props.selectedProduct?.id.toString() as string,
    productName: props.selectedProduct?.name as string,
  });
});
const includeAndClose = (cb: () => void) => {
  emit("includeAndGo");
  cb();
};
</script>
