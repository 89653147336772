<template>
  <div id="menuNavigator" class="menu-navigator-container">
    <TabMenu v-if="liveOrders.length" :model="liveOrders.map((it) => ({ name: it }))">
      <template #item="{ item }">
        <MenuNavigatorLiveOrder @click="liveOrderClickHandler(item.name)" />
      </template>
    </TabMenu>
    <MenuNavigatorProduct
      :products-navigators="productsNavigator"
      @product-navigator-change="handleParamsChange"
    />
    <MenuNavigatorCategories
      :category-navigators="categoryItems"
      @category-navigator-change="handleParamsChange"
    />
  </div>
</template>

<script setup lang="ts">
import { useMenuSlice } from "~/store/menuSlice";
import { useOrderMethodStore } from "~/store/orderMethodSlice";

const { logGoogleEventWithMiddleWare } = useConsentTracking();
const router = useRouter();
const route = useRoute();
const menuStore = useMenuSlice();
const orderMethodStore = useOrderMethodStore();
const { liveOrders } = storeToRefs(orderMethodStore);
const { productsNavigator, subCategories } = storeToRefs(menuStore);
const handleParamsChange = ({ key, value }: { key: string; value: string }) => {
  if (key === "product") {
    router.push({ path: route.path, query: { [key]: value } });
    logGoogleEventWithMiddleWare("page_view", { page_path: route.fullPath });
  } else {
    router.push({ path: route.path, query: { ...route.query, [key]: value } });
    logGoogleEventWithMiddleWare("page_view", { page_path: route.fullPath });
  }
  menuStore.setQueryParams(key, value);
};
const categoryItems = computed(() => {
  const categories = subCategories.value
    .filter((it) => it)
    .map((item) => {
      return {
        label: item,
        value: item,
      };
    });
  if (!categories.length) {
    return [];
  }
  categories.unshift({ label: "Alle", value: "all" });
  return categories;
});
const liveOrderClickHandler = (id: number) => {
  router.replace({
    path: "order-confirmation",
    query: {
      order_id: id,
    },
  });
  logGoogleEventWithMiddleWare("page_view", { page_path: route.fullPath });
};
</script>
