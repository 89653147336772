import useMenu from "~/composables/api/mock/useMenu";
import {
  type CustomizationElementType,
  type CustomizationOptionsType,
  type CustomizationsOptionsTypeInDetails,
  PizzaSize,
  type ProductEntity,
  type SupperCustomizationOptionsType,
} from "~/types/entities/product.type";
import type { CartItem } from "~/types/componentProps/cart";
import { useCartSlice } from "~/store/cartSlice";
import { useMenuSlice } from "~/store/menuSlice";

export default function useProductCustomization(productId: string) {
  const canBeHalfBacked = ref<boolean>(true);
  const { t } = useI18n();
  const menuStore = useMenuSlice();
  const { productsLists } = storeToRefs(menuStore);
  const cartStore = useCartSlice();
  const currentProduct = ref<string>(productId); // initialization of current selected product
  const cartAddableItem = ref<CartItem | null>(null);
  const { getProductCustomizationOptionById, getProductVariantPrice } = useMenu();
  const isLoadingCustomization = ref<boolean>(false);
  const customizationOptions = ref<CustomizationsOptionsTypeInDetails | null>(null);
  const latestExcludedStandardItemRef = ref<string[] | undefined>([]);
  const customizationOptionCache = ref<CustomizationOptionsType[] | null>(null);
  const showAllergens = ref<boolean>(false);
  const { logGoogleEventWithMiddleWare } = useConsentTracking();
  const totalQuantityAdded = computed(() => {
    return cartAddableItem.value?.product.customizableOptions.includedItem.reduce((acc, cur) => {
      return (acc += cur.quantity);
    }, 0);
  });
  const isValidToAddMore = computed(() => {
    if (totalQuantityAdded.value && cartAddableItem.value) {
      return totalQuantityAdded.value < cartAddableItem.value.product.maxAdditionalItems;
    } else {
      return true;
    }
  });
  const variantPrice = async (productId: string) => {
    isLoadingCustomization.value = true;
    const price = await getProductVariantPrice(productId);
    isLoadingCustomization.value = false;
    return price;
  };
  const isStandard = (item: CustomizationElementType) => item.isStandard;
  const isNotStandardItem = (item: CustomizationElementType) => !isStandard(item);
  const pushToLatestExcludedStandardItemRef = (id: string) => {
    const doseExist = latestExcludedStandardItemRef.value?.find((it) => it === id);
    if (!doseExist) {
      latestExcludedStandardItemRef.value?.push(id);
    }
  };
  const removeExchangedItemOfStandardItem = (standardItemRef: string) => {
    if (cartAddableItem.value && latestExcludedStandardItemRef.value) {
      const reverseToExtra = cartAddableItem.value.product.customizableOptions.includedItem.find(
        (item) => {
          return item.standardItemRef === standardItemRef;
        },
      );
      const indexInCartAble =
        cartAddableItem.value.product.customizableOptions.includedItem.findIndex((item) => {
          return item.standardItemRef === standardItemRef;
        });
      if (indexInCartAble >= 0 && reverseToExtra && reverseToExtra.addableItem) {
        cartAddableItem.value.product.customizableOptions.includedItem[indexInCartAble] = {
          ...reverseToExtra.addableItem,
          quantity: 1,
        };
      } else if (reverseToExtra && !reverseToExtra.addableItem) {
        cartAddableItem.value.product.customizableOptions.includedItem.splice(indexInCartAble, 1);
      }
      latestExcludedStandardItemRef.value = latestExcludedStandardItemRef.value.filter(
        (item) => item !== standardItemRef,
      );
    }
  };
  const reverseToZero = (
    standardRef: string,
    subProducts: { [key: string]: CustomizationElementType },
  ) => {
    const extraProduct = cartAddableItem.value?.product.customizableOptions.includedItem.find(
      (item) => {
        return subProducts[item.key] && item.price > 0;
      },
    );
    if (extraProduct) {
      const indexInCartAble =
        cartAddableItem.value?.product.customizableOptions.includedItem.findIndex(
          (item) => item.id === extraProduct.id,
        );
      if (indexInCartAble >= 0 && isNotStandardItem(subProducts[extraProduct.key])) {
        cartAddableItem.value.product.customizableOptions.includedItem[indexInCartAble] = {
          ...subProducts[extraProduct.key],
          standardItemRef: standardRef,
          quantity: 1,
        };
      } else {
        pushToLatestExcludedStandardItemRef(standardRef);
      }
    } else {
      pushToLatestExcludedStandardItemRef(standardRef);
    }
  };
  const loadCustomizationOptions = async (productId: string) => {
    isLoadingCustomization.value = true;
    const customizationResponse = await getProductCustomizationOptionById(productId);
    isLoadingCustomization.value = false;
    if (customizationResponse) {
      const { halfBakedItem, subProducts: customizationData } = customizationResponse;
      canBeHalfBacked.value = halfBakedItem;
      customizationOptionCache.value = customizationData;
      const supperCustomizationData: SupperCustomizationOptionsType[] = customizationData.map(
        (item) => {
          return {
            ...item,
            subProductDetails: Object.keys(item.subProductDetails).map((key) => {
              return {
                self: item.subProductDetails[key],
                includeSelf() {
                  if (cartAddableItem.value && isValidToAddMore.value) {
                    if (isNotStandardItem(this.self)) {
                      const exchangeRef = (() => {
                        let index = -1;
                        const temp = latestExcludedStandardItemRef.value?.find((it, ind) => {
                          const founded = Object.keys(item.subProductDetails).find(
                            (key) => item.subProductDetails[key].id === it,
                          );
                          if (founded) {
                            index = ind;
                            return true;
                          }
                          return false;
                        });
                        if (temp) {
                          latestExcludedStandardItemRef.value?.splice(index, 1);
                          return temp;
                        }

                        return "";
                      })();
                      if (exchangeRef) {
                        if (this.self.isChangeable) {
                          cartAddableItem.value.product.customizableOptions.includedItem.push({
                            ...this.self,
                            quantity: 1,
                            standardItemRef: exchangeRef,
                          });
                          return;
                        }
                      }
                      if (this.self.addableItem) {
                        cartAddableItem.value.product.customizableOptions.includedItem.push({
                          ...this.self.addableItem,
                          quantity: 1,
                        });
                      }
                    } else if (isStandard(this.self) && this.self.removableItem) {
                      cartAddableItem.value.product.customizableOptions.excludedItems =
                        cartAddableItem.value.product.customizableOptions.excludedItems.filter(
                          (it) => !(it.id === this.self.removableItem?.id),
                        );
                      // remove all exchanged normal which was added for this standard item;
                      removeExchangedItemOfStandardItem(this.self.id);
                    }
                  } else {
                    useNuxtApp().$toast.error(
                      t("max10error", {
                        context: cartAddableItem.value?.product.maxAdditionalItems,
                      }),
                    );
                  }
                },
                excludeSelf() {
                  if (isStandard(this.self) && cartAddableItem.value && this.self.removableItem) {
                    cartAddableItem.value.product.customizableOptions.excludedItems.push(
                      this.self.removableItem,
                    );
                    cartAddableItem.value.product.customizableOptions.includedItem =
                      cartAddableItem.value.product.customizableOptions.includedItem.filter(
                        (it) => !(it.id === this.self.addableItem?.id),
                      );
                    reverseToZero(this.self.id, item.subProductDetails);
                  } else if (isNotStandardItem(this.self) && cartAddableItem.value) {
                    const toBeExcluded =
                      cartAddableItem.value.product.customizableOptions.includedItem.find(
                        (it) => it.id === this.self.id || it.id === this.self.addableItem?.id,
                      );
                    cartAddableItem.value.product.customizableOptions.includedItem =
                      cartAddableItem.value.product.customizableOptions.includedItem.filter(
                        (it) => !(it.id === toBeExcluded?.id),
                      );
                    if (toBeExcluded && toBeExcluded.isChangeable && toBeExcluded.standardItemRef) {
                      reverseToZero(toBeExcluded.standardItemRef, item.subProductDetails);
                    }
                  }
                },
                isIncluded() {
                  return computed(() => {
                    if (isStandard(this.self)) {
                      return !cartAddableItem.value?.product.customizableOptions.excludedItems.find(
                        (it) => it.id === this.self.removableItem?.id,
                      );
                    } else {
                      return !!cartAddableItem.value?.product.customizableOptions.includedItem.find(
                        (it) => it.id === this.self.id || it.id === this.self.addableItem?.id,
                      );
                    }
                  });
                },
                addAsExtraItem() {
                  if (cartAddableItem.value && this.self.addableItem && isValidToAddMore.value) {
                    const doseExist =
                      cartAddableItem.value.product.customizableOptions.includedItem.find(
                        (it) => it.id === this.self.addableItem?.id,
                      );
                    if (!doseExist) {
                      cartAddableItem.value.product.customizableOptions.includedItem.push({
                        ...this.self.addableItem,
                        quantity: 1,
                      });
                    }
                  } else {
                    useNuxtApp().$toast.error(
                      t("max10error", {
                        context: cartAddableItem.value?.product.maxAdditionalItems,
                      }),
                    );
                  }
                },
                removeAsExtraItem() {
                  if (cartAddableItem.value && this.self.addableItem) {
                    cartAddableItem.value.product.customizableOptions.includedItem =
                      cartAddableItem.value.product.customizableOptions.includedItem.filter(
                        (it) => it.id !== this.self.addableItem.id,
                      );
                  }
                },
                myExtraIncludedItems() {
                  return computed(() => {
                    return !!cartAddableItem.value?.product.customizableOptions.includedItem.find(
                      (it) => it.id === this.self.addableItem?.id,
                    );
                  });
                },
                myPrice() {
                  return computed(() => {
                    const isExchangePossible = computed(() => {
                      const temp = latestExcludedStandardItemRef.value?.filter((it, _ind) => {
                        const founded = Object.keys(item.subProductDetails).find(
                          (key) => item.subProductDetails[key].id === it,
                        );
                        if (founded) {
                          return true;
                        }
                        return false;
                      }).length;
                      return !!(temp && temp > 0);
                    });
                    if (isExchangePossible.value) {
                      return this.self.price;
                    }
                    return (
                      cartAddableItem.value?.product.customizableOptions.includedItem.find(
                        (it) => it.id === this.self.id || it.id === this.self.addableItem?.id,
                      )?.price ??
                      this.self.addableItem?.price ??
                      this.self.price
                    );
                  });
                },
                myAllergens() {
                  return computed(() => {
                    if (showAllergens.value) {
                      return this.self.allergens.reduce((acc, curr) => {
                        return acc + curr.description;
                      }, "");
                    } else {
                      return "";
                    }
                  });
                },
              };
            }),
          };
        },
      );
      customizationOptions.value = customizationOptions.value
        ? {
            ...customizationOptions.value,
            [productId]: supperCustomizationData,
          }
        : { [productId]: supperCustomizationData };
    }
  };
  const reInitializeSelectedProduct = (productId: string, price: number) => {
    // this function will change current product id
    // base on that we will re fetch customization items
    // reset cartAddableItem
    currentProduct.value = productId;
    if (cartAddableItem.value) {
      cartAddableItem.value.productId = productId;
      cartAddableItem.value.product.id = productId;
      cartAddableItem.value.product.price = price;
      if (cartAddableItem.value.product.customizableOptions.includedItem.length) {
        cartAddableItem.value.product.customizableOptions.includedItem = [];
        cartAddableItem.value.product.customizableOptions.excludedItems = [];
      }
    }
  };
  const initializeCartAddableItem = (item: CartItem) => {
    cartAddableItem.value = item;
  };
  watch(
    () => currentProduct.value,
    () => {
      loadCustomizationOptions(currentProduct.value);
    },
    { immediate: true },
  );
  const productPrice = computed(() => {
    if (cartAddableItem.value) {
      return (
        Math.max(
          cartAddableItem.value?.product.price ?? 0,
          cartAddableItem.value?.product.secondHalf?.price ?? 0,
        ) +
        cartAddableItem.value?.product.customizableOptions.includedItem.reduce((acc, curr) => {
          return acc + curr.price * (curr.quantity ?? 1) || 0;
        }, 0)
      );
    }
    return 0;
  });
  const increaseCartAddableItemQuantity = () => {
    if (cartAddableItem.value) {
      cartAddableItem.value.quantity++;
    }
  };
  const decreaseCartAddableItemQuantity = () => {
    if (cartAddableItem.value && cartAddableItem.value.quantity > 0) {
      cartAddableItem.value.quantity--;
    }
  };
  const injectToCart = () => {
    if (cartAddableItem.value && cartAddableItem.value.quantity > 0) {
      cartStore.addToCart(cartAddableItem.value);
      logGoogleEventWithMiddleWare("add_to_cart", {
        productId: cartAddableItem.value.productId.toString(),
        productName: cartAddableItem.value.product.name,
        quantity: cartAddableItem.value.quantity.toString(),
      });
    }
  };
  const changeHalfBackedStatus = (value: boolean) => {
    if (cartAddableItem.value) {
      cartAddableItem.value.product.orderAsHalfBaked = value;
    }
  };
  const halfBakedStatus = computed(() => cartAddableItem.value?.product.orderAsHalfBaked ?? false);
  const enableHalfAndHalf = ref<boolean>(false);
  const changeHalfAndHalfStatus = (value: boolean) => {
    if (cartAddableItem.value) {
      enableHalfAndHalf.value = value;
      if (value) {
        cartAddableItem.value.product.customizableOptions = {
          includedItem: [],
          excludedItems: [],
        };
      }
      if (!value) {
        cartAddableItem.value.product.secondHalf = null;
      }
    }
  };
  const halfAndHalfPizzaList = computed(() => {
    if (enableHalfAndHalf.value) {
      return productsLists.value
        .filter(
          (item) =>
            item.subCategoryName === cartAddableItem.value?.product.section.sectionDescription,
        )[0]
        .productDetails.filter((it) => it.id !== cartAddableItem.value?.productId);
    }
    return [];
  });

  const canBeHalfAndHalf = computed(() => {
    return (
      cartAddableItem.value?.product.size === PizzaSize.LARGE &&
      cartAddableItem.value.product.isSplittable
    );
  });
  const setSecondHalf = (product: ProductEntity) => {
    cartAddableItem.value!.product.secondHalf = { ...product };
  };
  const secondHalf = computed(() => {
    return cartAddableItem.value?.product.secondHalf ?? null;
  });
  return {
    isLoadingCustomization,
    loadCustomizationOptions,
    customizationOptions,
    variantPrice,
    reInitializeSelectedProduct,
    initializeCartAddableItem,
    cartAddableItem,
    productPrice,
    increaseCartAddableItemQuantity,
    decreaseCartAddableItemQuantity,
    injectToCart,
    showAllergens,
    changeHalfBackedStatus,
    halfBakedStatus,
    enableHalfAndHalf,
    changeHalfAndHalfStatus,
    halfAndHalfPizzaList,
    canBeHalfAndHalf,
    secondHalf,
    setSecondHalf,
    canBeHalfBacked,
  };
}
