<template>
  <Dialog
    v-model:visible="showDealCustomizationModal"
    modal
    :position="position"
    :pt="{
      root: 'border-none',
      mask: {
        style: 'backdrop-filter: blur(2px)',
      },
    }"
  >
    <template #container="{ closeCallback }">
      <DealController
        :modal-close-action="() => closeDealCustomizationModal(closeCallback)"
        :deal-hook="useDealControl"
      >
        <DealSubproduct
          :screen-hook="useDealMenuScreenController"
          :modal-close-action="() => closeDealCustomizationModal(closeCallback)"
        />
      </DealController>
    </template>
  </Dialog>
</template>

<script setup lang="ts">
import { useProductsStore } from "~/store/productSlice";

const { width } = useWindowSize();
const productSlice = useProductsStore();
const { showDealCustomizationModal, selectedDealProduct } = storeToRefs(productSlice);
const position = computed(() => {
  return width.value < 500 ? "bottom" : "center";
});
const { useDealControl, useDealMenuScreenController } = useDealCustomization();
const closeDealCustomizationModal = (cb: () => void) => {
  selectedDealProduct.value = null;
  cb();
};
</script>

<style scoped></style>
