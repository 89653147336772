<template>
  <Chip
    :label="label"
    :class="isActiveCategory ? 'active' : ''"
    @click="clickHandler(value)"
    :style="{ width: 'auto' }"
  />
</template>

<script setup lang="ts">
import type { MenuNavigatorCategorySelectorsProps } from "~/types/componentProps/MenuNavigatorProduct";

const route = useRoute();

const props = defineProps<MenuNavigatorCategorySelectorsProps>();

const isActiveCategory = computed(() => {
  return props.value === (route.query.category ?? "all");
});
</script>
