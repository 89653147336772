<template>
  <div class="header">
    <div class="w-full flex flex-row-reverse">
      <div class="close-icon" @click="emit('closeModal')">
        <IconsCloseButtonIcon />
      </div>
    </div>
    <p class="title">{{ headerTitle }}</p>
  </div>
</template>

<script setup lang="ts">
defineProps({
  headerTitle: {
    type: String,
    default: "Default Header",
  },
});
const emit = defineEmits(["closeModal"]);
</script>

<style scoped></style>
