<template>
  <div>
    <TabMenu
      v-model:active-index="activeIndex"
      :model="productOption"
      @tab-change="handleActiveTabChange"
    />
  </div>
</template>

<script setup lang="ts">
import type { MenuNavigatorProductProps } from "~/types/componentProps/MenuNavigatorProduct";

const route = useRoute();
const emit = defineEmits(["productNavigatorChange"]);
const props = defineProps<MenuNavigatorProductProps>();
const productOption = computed(() => {
  return props.productsNavigators.map((item) => {
    return {
      label: item.categoryName !== "" ? item.categoryName : "hello",
      ...item,
    };
  });
});
const activeIndex = computed(() => {
  if (!route.query.product) {
    return productOption.value.findIndex((category) => category.isDefault) || 0;
  }
  const productKey: string = (route.query.product as string) ?? "";
  return productOption.value.findIndex((category) => category.label === productKey);
});
const handleActiveTabChange = ({ index }: { index: number }) => {
  emit("productNavigatorChange", {
    key: "product",
    value: productOption.value[index].label,
  });
};
</script>
