<template>
  <div
    v-if="cartLength > 0"
    class="md:hidden phone-cart-indicator"
    @click="cartStore.toggleCartShow"
  >
    <div class="quantity-badge">
      <p>{{ cartLength }}</p>
    </div>

    <p>{{ $t("Gotocheckout") }}</p>

    <div class="price">
      <p>{{ cartTotalPrice }} kr</p>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useCartSlice } from "~/store/cartSlice";

const cartStore = useCartSlice();
const { cartLength, cartTotalPrice } = storeToRefs(cartStore);
</script>
