<template>
  <ReusableModal
    :visible="modalPop"
    :style="{ width: '28rem', maxHeight: '50vh' }"
    @dialog-toggle="popModalUp"
  >
    <div class="flex flex-column justify-content-start align-content-start gap-2">
      <div
        v-for="item of halfAndHalfPizzaList"
        :key="item.id"
        class="half-and-half-option-container"
      >
        <div class="flex justify-content-start align-items-start gap-3">
          <img :src="item.img" alt="pizza-image" />
          <div class="flex flex-column justify-content-start align-content-start gap-1">
            <p class="title">{{ item.name }}</p>
            <p class="desc">{{ item.dsc }}</p>
          </div>
        </div>
        <div>
          <RadioButton v-model="chosenHalf" :input-id="item.id" :value="item.id" />
        </div>
      </div>
    </div>
  </ReusableModal>
  <div class="method-input place justify-content-between gap-3" @click="popModalUp">
    <div class="flex justify-content-start align-items-center gap-3">
      <img
        v-if="props.selectedHalf"
        :src="props.selectedHalf?.img"
        alt="pizza-image"
        :style="{ height: '48px', width: '48px' }"
      />
      <p v-else class="method-input-title">{{ $t("halfAndHalfPlaceHolder") }}</p>
      <div v-if="props.selectedHalf" class="flex justify-content-start align-items-center gap-2">
        <div class="flex flex-column justify-content-between align-items-start">
          <p class="title">{{ props.selectedHalf?.name }}</p>
          <p class="sub-title">{{ props.selectedHalf?.dsc }}</p>
        </div>
      </div>
      <div v-else></div>
    </div>
    <div>
      <i class="pi pi-chevron-down" />
    </div>
  </div>
</template>

<script setup lang="ts">
import type { ProductEntity } from "~/types/entities/product.type";

const emit = defineEmits(["set-selected-half"]);
const props = defineProps({
  halfAndHalfPizzaList: {
    type: Array as PropType<ProductEntity[]>,
    default: () => [],
  },
  selectedHalf: {
    type: Object as PropType<ProductEntity | null>,
    default: null,
  },
});
const modalPop = ref(false);
const popModalUp = () => {
  modalPop.value = !modalPop.value;
};
const chosenHalf = computed({
  get() {
    return props.selectedHalf?.id;
  },
  set(val: string) {
    emit("set-selected-half", val);
    popModalUp();
  },
});
</script>
