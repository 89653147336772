<template>
  <div class="screen-container">
    <DealSubproductDetails
      :show-modal="showDetailsScreen"
      :selected-product="customizaableProduct"
      :close-detail-modal="closeDetailModal"
      :next-step-name="nextStepName"
      @include-and-go="includeAndGoNext(customizaableProduct!)"
    >
    </DealSubproductDetails>
    <div v-if="!(activeMenu as IStep).final" class="subproduct-container">
      <div
        v-for="product of activeMenu && (activeMenu as IStep).dealItems"
        :key="product.id"
        class="w-full"
      >
        <DealSubproductItem :product="product" @click="toggleDetails(product)" />
        <div class="w-full mt-2">
          <Divider />
        </div>
      </div>
    </div>
    <DealSubproductFinal v-else :final-products="finalProducts">
      <ReusablePriceController
        v-if="cartAddableProduct"
        :price="cartAddableProduct.product.price || 0"
        :quantity="cartAddableProduct.quantity"
        @add-to-cart="increaseCartAddableItemQuantity"
        @remove-from-cart="decreaseCartAddableItemQuantity"
        @add-and-close="addCartHandler"
      />
    </DealSubproductFinal>
  </div>
</template>

<script setup lang="ts">
import type { IUseDealMenuScreenControl, IStep } from "~/composables/useDealCustomization";

const props = defineProps({
  screenHook: {
    type: Function as PropType<() => IUseDealMenuScreenControl>,
  },
  modalCloseAction: {
    type: Function as PropType<() => void>,
  },
});
const {
  activeMenu,
  includeAndGoNext,
  finalProducts,
  cartAddableProduct,
  injectToCart,
  increaseCartAddableItemQuantity,
  decreaseCartAddableItemQuantity,
  showDetailsScreen,
  toggleDetails,
  customizaableProduct,
  closeDetailModal,
  nextStepName,
} = props.screenHook!();
const addCartHandler = () => {
  injectToCart();
  props.modalCloseAction!();
};
</script>

<style scoped></style>
