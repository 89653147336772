<template>
  <Dialog
    v-model:visible="showProductOverViewModal"
    modal
    :position="position"
    :pt="{
      root: 'border-none',
      mask: {
        style: 'backdrop-filter: blur(2px)',
      },
    }"
  >
    <template #container="{ closeCallback }">
      <Card class="product-overview-card overflow-y-auto">
        <template #content>
          <div class="product-overview-header">
            <NuxtImg
              class="header-image"
              :src="selectedProduct?.img"
              :placeholder="loadingBox"
              :alt="selectedProduct?.name"
            >
              <template #error>
                <img :src="loadingBox" :alt="selectedProduct?.name" class="header-image" />
              </template>
            </NuxtImg>
            <div class="close-icon" @click="closeModal(closeCallback)">
              <IconsCloseButtonIcon />
            </div>
          </div>
          <div class="product-overview-content h-30rem">
            <div class="details">
              <div class="w-full">
                <p class="text-center w-full">
                  <span class="peppes-subtitle">{{
                    selectedProduct?.name.split(".").length === 2
                      ? selectedProduct.name.split(".")[0] + "."
                      : ""
                  }}</span>
                  <span class="peppes-title">{{
                    selectedProduct?.name.split(".")[1] ?? selectedProduct?.name
                  }}</span>
                  <span class="ml-2">
                    <IconsVegIcon v-if="selectedProduct?.isVegan" />
                  </span>
                  <span class="ml-2">
                    <component :is="attributesComponents[selectedProduct?.spiceLevel]" />
                  </span>
                </p>
              </div>
              <p class="text-center description">
                {{ selectedProduct?.dsc }}
              </p>
            </div>
            <!--           start of selecting pizza size-->
            <div
              v-if="allVariants.length > 0"
              class="w-full flex flex-row justify-content-between align-items-center gap-3"
            >
              <div
                v-if="smallVariant"
                :class="`product-size-selector ${selectedVariants === smallVariant?.id && 'active'}`"
                @click="!enableHalfAndHalf && setSelectedVariant(smallVariant?.id, PizzaSize.SMALL)"
              >
                <div class="w-full flex flex-row justify-content-center align-items-center">
                  <div class="size-title-container">
                    <p class="size-title">{{ $t("Small") }}</p>
                  </div>
                </div>
                <p class="size-price">
                  {{ smallVariant?.price }}
                  ,-
                </p>
              </div>
              <div
                v-if="largeVariant"
                :class="`product-size-selector ${selectedVariants === largeVariant.id && 'active'}`"
                @click="setSelectedVariant(largeVariant.id, PizzaSize.LARGE)"
              >
                <div class="w-full flex flex-row justify-content-center align-items-center">
                  <div class="size-title-container">
                    <p class="size-title">{{ $t("large") }}</p>
                  </div>
                </div>
                <p class="size-price">{{ largeVariant.price }} ,-</p>
              </div>
            </div>
            <div
              v-if="allVariants.length > 0 && glutenFreeVariant"
              :class="`product-size-selector ${selectedVariants === glutenFreeVariant?.id && 'active'}`"
              @click="
                !enableHalfAndHalf &&
                setSelectedVariant(glutenFreeVariant?.id, PizzaSize.GLUTONFREE)
              "
            >
              <div class="w-full flex flex-row justify-content-between align-content-center p-2">
                <div
                  class="w-full flex flex-column justify-content-start align-content-start gap-1"
                >
                  <div class="w-full flex flex-row justify-content-center align-items-center">
                    <div class="size-title-container">
                      <p class="size-title">{{ glutenFreeVariant.type }}</p>
                    </div>
                  </div>
                  <p class="sub-title text-center">{{ $t("Smallpizzaonly") }}</p>
                </div>

                <p class="size-price">{{ glutenFreeVariant?.price }} ,-</p>
              </div>
            </div>
            <!--            end of selecting Healthy version-->
            <!--            pizza customize-->
            <div v-if="isPizzaOnly" class="w-full">
              <ReusablePizzaCustomizerScalaton v-if="isLoadingCustomization" />
              <ReusablePizzaCustomizer
                v-else
                :customization-options="
                  selectedVariants && customizationOptions
                    ? customizationOptions[selectedVariants]
                    : []
                "
                :products-response="selectedProduct"
                :half-backed-status="halfBakedStatus"
                :change-half-backed-status="changeHalfBackedStatus"
                :half-and-half-status="enableHalfAndHalf"
                :change-half-and-half-status="changeHalfAndHalfStatus"
                :half-and-half-pizza-list="halfAndHalfPizzaList"
                :can-be-half-and-half="canBeHalfAndHalf"
                :second-half="secondHalf ?? null"
                :set-second-half="setSecondHalf"
                :can-be-half-backed="canBeHalfBacked"
              />
              <div
                class="mt-4 flex flex-row justify-content-start align-items-center gap-4"
                :style="{ marginBottom: '8rem' }"
              >
                <p>{{ $t("Showallergens") }}</p>
                <InputSwitch v-model="showAllergens" width="40" checked-bg="#CD1719" />
                <p v-if="showAllergens">
                  {{ selectedProduct?.allergens.map((curr) => curr.description).join(", ") }}
                </p>
              </div>
            </div>
            <!--            end of pizza customize-->
          </div>
          <ReusablePriceController
            v-if="cartAddableItem"
            :price="productPrice || 0"
            :quantity="cartAddableItem.quantity"
            @add-to-cart="AddProductToCart"
            @remove-from-cart="decreaseItemInCart"
            @add-and-close="addCartHandler(closeCallback)"
          />
        </template>
      </Card>
    </template>
  </Dialog>
</template>

<script setup lang="ts">
import { useProductsStore } from "~/store/productSlice";
import loadingBox from "assets/logo/loading_box.svg";
import { PizzaSize, ProductSize } from "~/types/entities/product.type";
import useProductCustomization from "~/composables/useProductCustomization";
import IconsVegIcon from "~/components/icons/VegIcon.vue";
import IconsSpicyX2Icon from "~/components/icons/SpicyX2Icon.vue";
import IconsSpicyX3Icon from "~/components/icons/SpicyX3Icon.vue";
import { useCartSlice } from "~/store/cartSlice";
import useSpecialLayoutHandlingForProduct from "~/composables/useSpeacialLayoutHandlingForProduct";

const { width } = useWindowSize();
const { logGoogleEventWithMiddleWare } = useConsentTracking();
const productSlice = useProductsStore();
const { showProductOverViewModal, selectedProduct } = storeToRefs(productSlice);
const {
  isLoadingCustomization,
  customizationOptions,
  variantPrice,
  reInitializeSelectedProduct,
  initializeCartAddableItem,
  cartAddableItem,
  productPrice,
  increaseCartAddableItemQuantity,
  decreaseCartAddableItemQuantity,
  injectToCart,
  showAllergens,
  changeHalfBackedStatus,
  halfBakedStatus,
  enableHalfAndHalf,
  changeHalfAndHalfStatus,
  halfAndHalfPizzaList,
  canBeHalfAndHalf,
  secondHalf,
  setSecondHalf,
  canBeHalfBacked
} = useProductCustomization(selectedProduct.value?.id ?? "");
const cartStore = useCartSlice();
const { isPizzaOnly } = useSpecialLayoutHandlingForProduct();
const allVariants = computed(() => {
  if (selectedProduct.value && selectedProduct.value.relatedProducts.length > 0) {
    const variants = [...selectedProduct.value.relatedProducts];
    variants[variants.length] = {
      id: selectedProduct.value.id,
      price: selectedProduct.value.price,
      type: "",
      size: (selectedProduct.value.size as unknown as ProductSize) || ProductSize.LARGE,
    };
    return variants;
  } else {
    return [];
  }
});
const selectedVariants = ref(selectedProduct.value?.id);
const setSelectedVariant = (id: string, size: PizzaSize) => {
  selectedVariants.value = id;
  if (cartAddableItem.value) {
    cartAddableItem.value.product.size = size;
  }
};

const closeModal = (cb: () => void) => {
  selectedProduct.value = null;
  cb();
};
const attributesComponents = shallowRef({
  Veg: IconsVegIcon,
  Medium: IconsSpicyX2Icon,
  High: IconsSpicyX3Icon,
});

watch(
  () => selectedProduct.value,
  async () => {
    if (selectedProduct.value && selectedProduct.value.relatedProducts.length) {
      const values = await Promise.all(
        selectedProduct.value?.relatedProducts.map((item) => {
          return variantPrice(item.id);
        }),
      );
      selectedProduct.value?.relatedProducts.forEach((item, index) => {
        item.price = values[index];
      });
    }
  },
  { immediate: true },
);
watch(
  () => showProductOverViewModal.value,
  () => {
    if (showProductOverViewModal.value) {
      const cartItem = cartStore.createCartAddableItem(selectedProduct.value);
      if (cartItem) {
        initializeCartAddableItem(cartItem);
      }
    }
  },
  { immediate: true },
);

watch(
  () => selectedVariants.value,
  () => {
    reInitializeSelectedProduct(
      selectedVariants.value ?? "",
      allVariants.value.find((item) => item.id === selectedVariants.value)?.price ?? 0,
    );
  },
);

const AddProductToCart = () => {
  increaseCartAddableItemQuantity();
};
const decreaseItemInCart = () => {
  decreaseCartAddableItemQuantity();
};
const addCartHandler = (cb: () => void) => {
  if (cartAddableItem.value && cartAddableItem.value.quantity > 0) {
    injectToCart();
    closeModal(cb);
  }
};

const smallVariant = computed(() => {
  return allVariants.value.find(
    (variant) => variant.size === ProductSize.SMALL && variant.type !== "Gluten free",
  );
});

const largeVariant = computed(() => {
  return allVariants.value.find((variant) => variant.size === ProductSize.LARGE);
});

const glutenFreeVariant = computed(() => {
  return allVariants.value.find(
    (variant) => variant.size === ProductSize.SMALL && variant.type === "Gluten free",
  );
});

const position = computed(() => {
  return width.value < 500 ? "bottom" : "center";
});
onMounted(() => {
  useNuxtApp().$firebaseTrackingFunctions.trackViewProductDetails(
    selectedProduct.value?.id.toString() || "",
  );
  logGoogleEventWithMiddleWare("select_item", {
    productId: selectedProduct.value?.id.toString() as string,
    productName: selectedProduct.value?.name as string,
  });
});
</script>
